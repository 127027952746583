import React, { useState, useEffect, useRef } from "react";
import { PATTERNS } from "../validetion";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  CircularProgress,
  Divider,
  CardActions,
  Modal,
  Box,
} from "@mui/material";
import {
  CustomRequiredLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import SubmitButton from "../coustombutton/SubmitButton";
import {
  useGetAddOrderCampaignName,
  useGetAddOrderInfluencers,
  useGetInfluencer,
  useGetInfluencerUpi,
} from "../customHooks/customHook";
import { postFormData } from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AddUpiInfluencerPopup from "../adminnote/AddUpiInfluencerPopup";
import InfluencerPopup from "../adminnote/InfluencerPopup";
import CloseIcon from "@mui/icons-material/Close";
import OrderSuccessPopup from "../adminnote/AddOrderPopup";
import Resizer from 'react-image-file-resizer';

const AddOrder = () => {
  const [formData, setFormData] = useState({
    influncername: {
      value: "",
      isValid: true,
    },
    orderid: {
      value: "",
      isValid: true,
    },
    ordervalue: {
      value: "",
      isValid: true,
    },
    deliverydate: {
      value: "",
      isValid: true,
    },
    upiid: {
      value: "",
      isValid: true,
    },
    upiholdername: {
      value: "",
      isValid: true,
    },
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [productName, setProductName] = useState("");
  const [upiIdName, setUpiIdName] = useState({
    upiId: "",
    holderName: "",
  });
  const [dummyUpiData, setDummyUpiData] = useState("");
  const [isupiTrue, setIsupiTrue] = useState(false);
  const [paymentModeName, setPaymentModeName] = useState("");
  const [orderPlatform, setOrderPlatform] = useState("");
  const [orderScreenshotFile, setOrderScreenshotFile] = useState(null);
  const [reviewScreenshotFile, setReviewScreenshotFile] = useState(null);
  const [influncerName, setInfluncerName] = useState("");
  const [camapignName, setCamapignName] = useState("");
  const [isDisabledNew, setIsDisabledNew] = useState(false);
  const [addupiId, setAddupiId] = useState(false);
  const [addupiHolderName, setAddupiHolderName] = useState(false);
  const [loading, setLoading] = useState(true);
  const [defaultUpiId, setDefaultUpiId] = useState(null);
  // const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [deleveryDate, setDeleveryDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const addPrefillCampaignDetails = useGetAddOrderInfluencers();
  const [openModalNote, setOpenModalNote] = useState(false);
  const [openModalUpi, setOpenModalUpi] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [triggerRefreshUpi, setTriggerRefreshUpi] = useState(false);
  const [brands, setBrands] = useState(addPrefillCampaignDetails?.allstatus);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [submitupiData, setSubmitupiData] = useState(false);
  const [submitInfluemncerData, setSubmitInfluemncerData] = useState(false);
  const [dummyInfluencerData, setDummyInfluencerData] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const { allstatus: addPrefillCampaignName, campaignError } =
    useGetAddOrderCampaignName(
      selectedBrand?._id,
      selectedProduct?.value,
      selectedPlatform?.value
    );
  const [openZoomModal, setOpenZoomModal] = useState(false);

  const [campaignNamePrefill, setCampaignNamePrefill] = useState(
    addPrefillCampaignName
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const getInfluencer = useGetInfluencer();
  const getInfluencerUpi = useGetInfluencerUpi(influncerName?._id);
  const [currentImage, setCurrentImage] = useState("");

  const [upiDatacombinedData, setUpiDatacombinedData] = useState();
  const [influencerDatacombinedData, setInfluencerDatacombinedData] = useState(
    getInfluencer?.allstatusExport
  );

  const productRef = useRef(null);
  const platformRef = useRef(null);
  const campaignRef = useRef(null);
  const upiRef = useRef(null);

  const handleBrand = (selectedOption) => {
    const selectedBrand = brands.find(
      (brand) => brand._id === selectedOption.value
    );
    setSelectedBrand(selectedBrand);
    // Update products and platforms based on the selected brand
    setProducts(
      selectedBrand.products.map((product) => ({
        value: product._id,
        label: product.productName,
      }))
    );
    setPlatforms(
      selectedBrand.campaignPlatforms.map((platform) => ({
        value: platform._id,
        label: platform.platform,
      }))
    );

    setSelectedProduct(null);
    setSelectedPlatform(null);
  };

  // const handleReviewScreenShot = (e) => {
  //   setSelectedImages([
  //     ...selectedImages,
  //     ...Array.from(e.target.files).map((file) => ({
  //       file,
  //       url: URL.createObjectURL(file),
  //     })),
  //   ]);
  //   e.target.value = null;
  // };
 
  const handleReviewScreenShot = async (e) => {  
    const newFiles = Array.from(e.target.files);  
  
    // Function to compress a single image with a target size  
    const compressImage = async (file) => {  
      const maxSize = 40 * 1024; // Target size 40 KB  
      let quality = 90; // Start with a high quality  
      let compressedFile;  
  
      do {  
        compressedFile = await new Promise((resolve) => {  
          Resizer.imageFileResizer(  
            file,  
            1080, // Max width  
            1080, // Max height  
            'JPEG', // Output type  
            quality, // Quality (0-100)  
            0, // Rotation  
            (uri) => {  
              resolve(uri);  
            },  
            'blob' // Return type  
          );  
        });  
  
        // Decrease quality for the next attempt if still too large  
        if (compressedFile.size > maxSize) {  
          quality -= 10; // Decrease quality by 10 for the next attempt  
        }  
      } while (compressedFile.size > maxSize && quality > 0); // Loop until size is under 40 KB or quality is 0  
  
      // Return the compressed file along with the original file's name  
      return {  
        file: compressedFile,  
        name: file.name // Original file name  
      };  
    };  
  
    // Compress files larger than 40 KB  
    const compressedFiles = await Promise.all(newFiles.map((file) => compressImage(file)));  
  
    // Calculate total size of compressed files  
    const newFilesTotalSize = compressedFiles.reduce((acc, { file }) => acc + file.size, 0);  
    const existingFilesTotalSize = selectedImages.reduce((acc, imgObj) => acc + imgObj.file.size, 0);  
    const totalSize = newFilesTotalSize + existingFilesTotalSize;  
  
    // Check if total size exceeds 100 KB  
    if (totalSize > 100 * 1024) {  
      setErrorMessage('Please upload the order screenshot below 100 KB.');  
      e.target.value = null;  
      return;  
    }  
  
    setErrorMessage('');  
  
    // Update selected images  
    setSelectedImages([  
      ...selectedImages,  
      ...compressedFiles.map(({ file, name }) => {  
        return {  
          file,  
          url: URL.createObjectURL(file),  
          originalName: name // Save original filename here  
        };  
      }),  
    ]);
    
    e.target.value = null;  
  };
  const removeImage = (indexToRemove) => {
    setSelectedImages(
      selectedImages.filter((_, index) => index !== indexToRemove)
    );
  };
  const handleProduct = (selectedOption) => {
    setSelectedProduct(selectedOption);
  };
  useEffect(() => {
    if (addPrefillCampaignDetails?.allstatus) {
      setBrands(addPrefillCampaignDetails?.allstatus);
    }
  }, [addPrefillCampaignDetails?.allstatus]);
  useEffect(() => {
    if (addPrefillCampaignName) {
      setCampaignNamePrefill(addPrefillCampaignName[0]);
    }
  }, [addPrefillCampaignName]);

  useEffect(() => {
    if (productRef.current && productRef.current.clearValue) {
      productRef.current.clearValue();
    }
    if (platformRef.current && platformRef.current.clearValue) {
      platformRef.current.clearValue();
    }
    setCampaignNamePrefill(null);
  }, [selectedBrand]);
  useEffect(() => {
    if (campaignRef.current) {
      campaignRef.current.value = "";
    }
    setCampaignNamePrefill(null);
  }, [selectedBrand, selectedPlatform, selectedProduct]);

  const handlePlatform = (selectedOption) => {
    setSelectedPlatform(selectedOption);
  };

  const handleOpenModal = () => {
    setOpenModalNote(true);
  };
  const handleUpiOpenModal = () => {
    setOpenModalUpi(true);
  };
  const navigateToHome = () => {
    navigate("/influencer/order");
  };
  const handleCloseModal = () => {
    setOpenModalNote(false);
  };
  const handleUpiCloseModal = () => {
    setOpenModalUpi(false);
  };

  const handleUpiId = (selectedOption) => {
    setIsupiTrue(true);
    if (selectedOption) {
      const selectedUpiId = selectedOption.value;
      const selectedHolder = getInfluencerUpi.allstatusExport[0]?.upiData.find(
        (item) => item.upiId === selectedUpiId
      );
      if (selectedHolder) {
        setUpiIdName({
          upiId: selectedUpiId,
          holderName: selectedHolder.holderName,
        });
        setIsupiTrue(false);
      }
    } else {
      setUpiIdName({
        upiId: "",
        holderName: "",
      });
    }
    setAddupiId(false);
  };

  useEffect(() => {
    if (dummyUpiData) {
      setUpiIdName(dummyUpiData);
    }
  }, [dummyUpiData]);
  useEffect(() => {
    if (dummyInfluencerData) {
      setInfluncerName(dummyInfluencerData);
    }
  }, [dummyInfluencerData]);

  const handleInfluncer = (selectedOption) => {
    if (selectedOption) {
      setInfluncerName({
        _id: selectedOption.value,
        influencerName: selectedOption.label,
      });
    } else {
      setCamapignName("");
    }
  };
  useEffect(() => {
    setUpiDatacombinedData(getInfluencerUpi?.allstatusExport[0]?.upiData);
  }, [influncerName]);

  useEffect(() => {
    if (upiRef.current && upiRef.current.clearValue) {
      upiRef.current.clearValue();
    }
  }, [influncerName?._id]);
  const handleOrderScreenShot = (e) => {
    setOrderScreenshotFile(e.target.files[0]);
    // setOrderScreenshotFile(e.target.files[0].name);
  };

  const navigate = useNavigate();
  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };
  const refreshUpiData = () => {
    setTriggerRefreshUpi(!triggerRefreshUpi);
  };
  const refreshData = () => {
    setTriggerRefreshUpi(!triggerRefreshUpi);
  };
  // const refreshData = (newInfluencer) => {
  //   setTriggerRefresh(!triggerRefresh);
  //   // setInfluncerName(...influncerName, newInfluencer)
  //   if (newInfluencer) {
  //     setInfluncerName({
  //       influencerName: newInfluencer.data.influencerName,
  //       _id: newInfluencer.data._id,
  //     });
  //   }
  // };

  const createNewInfluencer = () => {
    handleOpenModal();
  };
  const handleDateChange = (event) => {
    const currentDate = new Date();
    const selectedDate = new Date(event.target.value);

    if (selectedDate > currentDate) {
      setSelectedDate("");
      toast.error("Order date should not be a future date.");
    } else {
      setSelectedDate(event.target.value);
    }
  };
  const handleDeliveryDateChange = (event) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const deliveryDate = new Date(event.target.value);
    deliveryDate.setHours(0, 0, 0, 0);

    if (deliveryDate < currentDate) {
      setDeleveryDate("");
      toast.error("Delivery date should not be in the past.");
    } else {
      setDeleveryDate(event.target.value);
    }
  };

  // const handleDeliveryDateChange = (event) => {
  //   const currentDate = new Date();
  //   const deleveryDate = new Date(event.target.value);

  //   if (deleveryDate < currentDate) {
  //     setDeleveryDate("");
  //     toast.error("Delivery date should not be in the past.");
  //   } else {
  //     setDeleveryDate(event.target.value);
  //   }
  // };
  const createNewUpi = () => {
    handleUpiOpenModal();
  };
  const handleUpiSubmit = (data) => {
    const upiIdName = data.upiId.toLowerCase();
    // Check if the influencer name already exists in the array
    if (
      getInfluencerUpi?.allstatusExport[0]?.upiData.some(
        (item) => item.upiId.toLowerCase() === upiIdName
      )
    ) {
      toast.error("upiId already exists.");
      // Show an error message or take appropriate action
      return;
    }

    setDummyUpiData(data);
    setUpiDatacombinedData(
      [...(getInfluencerUpi?.allstatusExport[0]?.upiData || []), data],
      data
    );
    setSubmitupiData(true);
  };
  // const handleAddInfluencerSubmit = (data) => {
  //   data._id = Math.random().toString(36).substring(2, 28);

  //   setDummyInfluencerData(data);
  //   setInfluencerDatacombinedData([...getInfluencer?.allstatusExport, data]);
  //   setSubmitInfluemncerData(true);
  // };
  const handleAddInfluencerSubmit = (data) => {
    // const influencerName = data.influencerName;
    // const capitalizedName = influencerName.charAt(0).toUpperCase() + influencerName.slice(1);
    const influencerName = data.influencerName.toLowerCase();

    // Check if the influencer name already exists in the array
    if (
      getInfluencer?.allstatusExport.some(
        (item) => item.influencerName.toLowerCase() === influencerName
      )
    ) {
      toast.error("Reviewer name already exists.");
      // Show an error message or take appropriate action
      return;
    }

    data._id = Math.random().toString(36).substring(2, 28);
    setDummyInfluencerData(data);
    setInfluencerDatacombinedData([...getInfluencer?.allstatusExport, data]);
    setSubmitInfluemncerData(true);
  };
  const handleOpenZoomModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setOpenZoomModal(true);
  };
  const handleCloseZoomModal = () => {
    setOpenZoomModal(false);
  };
  const handleOrder = (e) => {
    e.preventDefault();
    if (isDisabledNew || campaignError || OrderIdHypen) {
      return;
    }
    setIsDisabledNew(true);
    setFormSubmitted(true);

    const isFormValid =
      selectedBrand?._id &&
      selectedPlatform?.value &&
      selectedProduct?.value &&
      selectedImages?.length > 0 &&
      // orderScreenshotFile &&
      upiIdName?.upiId &&
      influncerName?._id &&
      formData.ordervalue.value &&
      formData.ordervalue.isValid &&
      formData.orderid.isValid &&
      formData.orderid.value &&
      selectedDate &&
      deleveryDate;
    if (isFormValid) {
      const data = {
        orderId: formData.orderid.value,
        orderValue: formData.ordervalue.value,
        influencer: influncerName?._id,
        orderDate: selectedDate,
        deliveryDate: deleveryDate,
        upiId: upiIdName?.upiId,
        holderName: upiIdName?.holderName,
        brand: selectedBrand?._id ? selectedBrand?._id : "",
        orderPlatform: selectedPlatform?.value ? selectedPlatform?.value : "",
        product: selectedProduct?.value ? selectedProduct?.value : "",
        campaign: campaignNamePrefill?._id ? campaignNamePrefill?._id : "",
      };

      if (dummyInfluencerData) {
        data.influencername = dummyInfluencerData?.influencerName;
      }
      // if (orderScreenshotFile) {
      //   data.orderScreenshot = orderScreenshotFile;
      // }

      const newData = new FormData();
      Object.keys(data).forEach((key) => {
        newData.append(key, data[key]);
      });
      // if (selectedImages) {
      //   selectedImages.forEach((file, index) => {
      //     newData.append("orderScreenshot", file.file);
      //   });
      // }
      if (selectedImages) {
        selectedImages.forEach(({ file, originalName }) => {
          // Convert the blob to a File object with the original name
          const fileWithOriginalName = new File([file], originalName, {
            type: file.type,
          });
      
          // Append the new File object to the FormData
          newData.append("orderScreenshot", fileWithOriginalName);
        });
      }
      
      postFormData(
        VisitorEndpoint.INFLUENCER_CREATE_ORDER,
        newData,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 201) {
              setIsPopupOpen(true);
              // navigate("/influencer/order");
            }
          }
          setIsDisabledNew(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);
    }
  };
const OrderIdHypen = selectedPlatform?.value === "65fae17c70f44cae8e6c063e" &&
!formData.orderid.value.includes("-")
  return (
    <>
      <OrderSuccessPopup
        open={isPopupOpen}
        navigateToHome={() => {
          navigateToHome();
          setIsPopupOpen(false);
        }}
      />
      <InfluencerPopup
        open={openModalNote}
        onClose={handleCloseModal}
        // editState={editState}
        onSuccess={refreshData}
        onFormSubmit={handleAddInfluencerSubmit}
      />
      <AddUpiInfluencerPopup
        open={openModalUpi}
        onClose={handleUpiCloseModal}
        onSuccess={refreshUpiData}
        onFormSubmit={handleUpiSubmit}
      />
      <Modal
        open={openZoomModal}
        onClose={handleCloseZoomModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <img
            src={currentImage}
            alt="Zoomed"
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
        </Box>
      </Modal>
      <div className="container_fluid_new">
        <div className="container_new">
          {/* {loading ? (
            <CircularProgress />
          ) : (
            <> */}
          <div>
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Order", href: "/influencer/order" },
                { name: "Add Order", href: "/influencer/order/add" },
              ]}
            />
          </div>
          <h1 className="have_solution_heading">Add Order</h1>
          <form onSubmit={handleOrder}>
            <Card sx={{ padding: "20px" }}>
              <CardContent>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Brand Name
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the brand for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <SingleSelect
                        options={brands.map((brand) => ({
                          value: brand._id,
                          label: brand.brandName,
                        }))}
                        value={selectedBrand}
                        onChange={handleBrand}
                        name="brand"
                        isClearable={false}
                      />
                      {formSubmitted && !selectedBrand?._id && (
                        <p className="input_text_error">
                          *Please select brand name.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Product Name
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the product for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <SingleSelect
                        ref={productRef}
                        options={products}
                        value={selectedProduct}
                        onChange={handleProduct}
                        name="product"
                      />
                      {formSubmitted && !selectedProduct?.value && (
                        <p className="input_text_error">
                          *Please select a product.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Order Platform
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the platform for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <SingleSelect
                        ref={platformRef}
                        options={platforms}
                        value={selectedPlatform}
                        onChange={handlePlatform}
                        name="platform"
                      />
                      {formSubmitted && !selectedPlatform?.value && (
                        <p className="input_text_error">
                          *Please select platform.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Campaign Name
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the Campaign for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        required
                        inputRef={campaignRef}
                        type="text"
                        id="outlined-required"
                        label="First Name"
                        helper
                        disabled
                        value={campaignNamePrefill?.campaignName}
                        size="small"
                      />
                      <p className="input_text_error">{campaignError}</p>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Reviewer Name
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the reviewer name who has placed an order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      onClick={createNewInfluencer}
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        alignSelf: "end",
                      }}
                    >
                      Ordering for someone else?
                    </p>
                    <FormControl sx={{ width: "100%" }}>
                      <SingleSelect
                        options={
                          submitInfluemncerData
                            ? influencerDatacombinedData?.map((item) => ({
                                // label: item.influencerName,
                                label: item.influencerName,
                                value: item._id,
                              }))
                            : getInfluencer?.allstatusExport.map((item) => ({
                                label: item.influencerName,
                                value: item._id,
                              }))
                        }
                        key={influncerName?.influencerName}
                        value={influncerName}
                        onChange={handleInfluncer}
                        name="flavor"
                        defaultValue={
                          influncerName && influncerName?.influencerName
                            ? {
                                label: influncerName?.influencerName,
                                value: influncerName?._id,
                              }
                            : ""
                        }
                      />
                      {formSubmitted && !influncerName?._id && (
                        <p className="input_text_error">
                          *Please select an Influencer.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                  {/* </div> */}
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>Order Id</CustomRequiredLabel>
                    <CustomSubheadingh>
                      Enter the Id for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        required
                        name="orderid"
                        type="text"
                        placeholder="Enter Order Id"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        onChange={(e) => {
                          setValues(e, "orderid", PATTERNS.ORDER_ID);
                        }}
                      />
                      {
                        selectedPlatform?.value === "65fae17c70f44cae8e6c063e" &&
                        !formData.orderid.value.includes("-") && (
                          <p className="input_text_error">
                          *Please enter the correct Order ID that matches your placed order.
                          </p>
                        )}

                      {formSubmitted && !formData.orderid.value && (
                        <p className="input_text_error">
                          *Please enter the Order Id.
                        </p>
                      )}
                      {formData.orderid.isValid || (
                        <p className="input_text_error">
                          *Please enter a valid Order ID. The '#' character is
                          not allowed.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Order Value
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Enter the value for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        required
                        name="ordervalue"
                        type="text"
                        onWheel={(e) => e.target.blur()}
                        placeholder="Enter Order Value"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        onChange={(e) => {
                          setValues(e, "ordervalue", PATTERNS.VALUES_DECIMAL);
                        }}
                      />
                      {formSubmitted && !formData.ordervalue.value && (
                        <p className="input_text_error">
                          *Please enter the order amount.
                        </p>
                      )}
                      {formData.ordervalue.isValid || (
                        <p className="input_text_error">
                          *Please enter only numbers.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Order Date
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Enter the date on which order was made.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        required
                        name="orderdate"
                        type="date"
                        placeholder="Enter Company Legal Address"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        onChange={handleDateChange}
                      />
                      {formSubmitted && !selectedDate && (
                        <p className="input_text_error">
                          *Please select the order date.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      {" "}
                      Delivery Date(Estimated){" "}
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Enter the date of product delivery.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        name="noofcontentpieces"
                        type="date"
                        placeholder="Enter Delivery Date"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        onChange={handleDeliveryDateChange}
                      />
                      {formSubmitted && !deleveryDate && (
                        <p className="input_text_error">
                          *Please enter the delivery date.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Order Screenshot
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Upload the screenshot of the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleReviewScreenShot}
                        multiple
                        className="bg-gray-10 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2"
                        style={{ color: "transparent" }}
                      />
                      {formSubmitted && !selectedImages.length > 0 && (
                        <p className="input_text_error">
                          *Please upload the order screenshot.
                        </p>
                      )}
                      {errorMessage && <p className="input_text_error">{errorMessage}</p>}

                      {selectedImages.map((image, index) => (
                        <div
                          key={index}
                          className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg max-sm:w-[100%]"
                        >
                          <div className="flex justify-between p-1">
                            <img
                              src={image.url}
                              alt={`Selected ${index}`}
                              className="w-8 h-8 object-cover  border border-gray-300 self-center"
                              onClick={() => handleOpenZoomModal(image.url)}
                            />
                            {/* <p className="ml-2">{image.file.name}</p> */}
                            <p className="ml-2">{image.originalName}</p>
                          </div>
                          <CloseIcon
                            onClick={() => removeImage(index)}
                            className="text-red-500 cursor-pointer ml-2"
                          />
                        </div>
                      ))}
                    </FormControl>
                    {/* <FormControl sx={{ width: "100%" }}>
                    <input type="file" accept="image/*"   onChange={handleOrderScreenShot} className="bg-gray-10 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2"/>
               

                      {formSubmitted && !orderScreenshotFile && (
                        <p className="input_text_error">
                          *Please upload the order screenshot.
                        </p>
                      )}
                    </FormControl> */}
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>UPI Id</CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the UPI details.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {influncerName?._id && (
                      <p
                        onClick={createNewUpi}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          alignSelf: "flex-end",
                        }}
                      >
                        Use Different UPI Id?
                      </p>
                    )}
                    <FormControl sx={{ width: "100%" }}>
                      <SingleSelect
                        options={
                          submitupiData
                            ? upiDatacombinedData &&
                              upiDatacombinedData?.map((item) => ({
                                label: item.upiId,
                                value: item.upiId,
                              }))
                            : getInfluencerUpi?.allstatusExport[0]?.upiData?.map(
                                (item) => ({
                                  label: item.upiId,
                                  value: item.upiId,
                                })
                              )
                        }
                        key={upiIdName?.upiId}
                        value={upiIdName}
                        ref={upiRef}
                        onChange={handleUpiId}
                        name="flavor"
                        defaultValue={
                          upiIdName && upiIdName?.upiId
                            ? {
                                label: upiIdName?.upiId,
                                value: upiIdName?.upiId,
                              }
                            : ""
                        }
                      />

                      {formSubmitted && !upiIdName?.upiId && (
                        <p className="input_text_error">
                          *Please select the UPI Id.
                        </p>
                      )}
                      {!influncerName?._id && isupiTrue && (
                        <p className="input_text_error">
                          *Please select the Reviewer first.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {/* <Card>
                  <CardContent>
                    <Divider sx={{ marginY: 1, borderBottom: "none" }} />
                    {/* {coverimageFile && ( */}
                {/* <img
                        style={{ width: "100px" }}
                        // src={URL.createObjectURL(coverimageFile)}
                        alt="Card"
                      /> */}
                {/* )} */}
                {/* </CardContent>
                  <CardActions>
                    <Button>"Remove"</Button>
                  </CardActions> */}
                {/* </Card> */}
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      UPI Holder Name
                    </CustomRequiredLabel>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        name="upiholdername"
                        type="text"
                        placeholder="Enter UPI Holder Name"
                        id="outlined-required"
                        label="First Name"
                        helper
                        value={upiIdName.holderName}
                        disabled
                        size="small"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <SubmitButton
                  type={isDisabledNew ? "button" : "submit"}
                  label={isDisabledNew ? "Loading..." : "Submit"}
                  disabled={isDisabledNew || !!campaignError || OrderIdHypen}
                />{" "}
              </CardContent>
            </Card>
          </form>
          {/* </>
          )} */}
        </div>
      </div>
    </>
  );
};
export default AddOrder;
// // </>
// {
//   /* )} */
// }
